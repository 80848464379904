@media screen and (max-width: 769px) {
    .App {
        padding-left : 1rem;
        padding-right: 1rem;
    }

    .ProfilePicture {
        height: 225px;
        width : 225px;
    }

    .Info {
        padding: 24px;
    }

    .Experience {
        padding: 24px;
    }
}

