.App {
    min-height   : 100vh;
    max-width    : 1440px;
    margin       : auto;
}

.ProfilePicture {
    height          : 250px;
    width           : 250px;
    border-radius   : 50%;
    background-image: url('./profile-image.png');
    background-size : cover;
    margin          : auto;
}

.PersonalInfo {
    margin-top: 36px;
}

.ContactInfo {
    margin-top: 36px;
}

.Content {
    width        : 100%;
    margin-top   : 132px;
    display      : flex;
    gap          : 36px;
    flex-wrap    : wrap;
    margin-bottom: 200px;
}

.Info {
    background-color: #171819;
    flex            : 1 1 0;
    padding         : 36px;
    height          : fit-content;
}

.Experience {
    background-color: #171819;
    flex            : 2 1 0;
    padding         : 36px;
}

.ExperienceIntro {
    font-size: 24px;
}

.ProjectWrapper {
    margin-top: 36px;
}

.ProjectWrapper span {
    font-weight: bold;
}

.ContactInfo a {
    color:         white;
    text-decoration: none;
}

.ContactInfo a:hover {
    color: #fc9400;
}