@media screen and (max-width: 769px) {
    .Header-content {
        height          : 96px;
        justify-content : center;
    }

    .Header-logo {
        height      : 54px;
        margin-right: 0;
    }
}
