.Header {
    position        : absolute;
    top             : 0;
    right           : 0;
    height          : 96px;
    width           : 100%;
    background-color: #171819;
    box-shadow      : rgba(132, 132, 132, 0.2) 0px 2px 8px 0px;

}

.Header-content {
    max-width       : 1440px;
    margin          : auto;
    height          : 96px;
    display         : flex;
    align-items     : center;
    justify-content : flex-end;
}

.Header-logo {
    height      : 76px;
    margin-right: 36px;
}